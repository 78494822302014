import React, { useEffect } from 'react';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const PrivateRoute = ({ children }: { children: any }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    if (isLoading || isAuthenticated) return;
    /**
    * v1.4.5 Issue#648 ログインしていない状態で「予定確定メール」内にある各URLをクリックした場合、期待するページ以外に遷移する
    * クエリパラメータの値を渡すように修正
    */
    loginWithRedirect({ appState: { returnTo: `${location.pathname}${location.search}` } });
   }, [isLoading, isAuthenticated, loginWithRedirect, location.pathname,location.search]);

  return isAuthenticated ? children : isLoading ? <></> : <Navigate to="/login" />;
};
