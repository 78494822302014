import axios from '@providers/axiosProvider';
import axios_ from 'axios';
import * as actions from './actions';
import {
  Address,
  AffiliateCompanyFormData,
  AffiliateUserFormData,
  APIDownloadBulkCreateFormatResponse,
  APIGetAddressResponse,
  APIGetAffiliateCompanyListResponse,
  APIGetAffiliateCompanyResponse,
  APIGetAffiliatesRequestParams,
  APIGetAffiliateUserListResponse,
  APIGetAffiliateUserResponse,
  SearchParameter,
} from './types';
import { LIST_SIZE_PER_PAGE } from '@utils/constants';
import { commonActions } from '@ducks/common';

/**
 * @summary 検索パラメータを元に連絡先ユーザのリストを取得
 * @param {number} page ページ番号
 * @param {number} sort ソート番号
 * @param {string} keyword 検索キーワード
 * @param {SearchParameter} params 詳細検索条件
 * @param {number[]} tagIdList 連絡先検索タグID
 * @returns {void}
 */
export const getAffiliateUserListAsync =
  (page: number, sort: number, keyword: string, params: SearchParameter, tagIdList: number[]) =>
  async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetAffiliatesRequestParams = {
      page: page,
      sort: sort,
      size_per_page: LIST_SIZE_PER_PAGE,
    };

    if (keyword) reqParams.keyword = keyword;
    if (params.affiliate_company_name)
      reqParams.affiliate_company_name = params.affiliate_company_name;
    if (params.user_name) reqParams.name = params.user_name;
    if (params.department) reqParams.department = params.department;
    if (params.position) reqParams.position = params.position;
    if (params.user_address) reqParams.address = params.user_address;
    if (tagIdList.length) reqParams.tag = tagIdList.join(',');

    await axios
      .get<APIGetAffiliateUserListResponse>('/affiliates/users', {
        params: reqParams,
      })
      .then((response) => {
        const { affiliate_user_list: userList, display_params: displayParams } =
          response.data.result;

        dispatch(actions.setUserList(userList));
        dispatch(actions.setDisplayParams(displayParams));
      })
      .catch((e) => {
        dispatch(actions.setUserList([]));
        dispatch(actions.setDisplayParams(null));
        alert(e.message);
      });

    dispatch(actions.setIsLoading(false));
  };

/**
 * @summary ユーザIDを元に連絡先ユーザを取得
 * @param {number} userId ユーザID
 * @returns {void}
 */
export const getAffiliateDetailUserAsync = (userId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetAffiliateUserResponse>(`/affiliates/users/${userId}`)
    .then((response) => {
      const userDetail = response.data.result;
      dispatch(actions.setUserDetail(userDetail));
    })
    .catch((e) => {
      dispatch(actions.setUserDetail(null));
      alert(e.message);
    });

  dispatch(actions.setIsLoading(false));
};

/**
 * @summary 新規連絡先ユーザの一括登録用フォーマットファイルを取得する
 * @returns { {fileName: string, fileContent: string} | null }
 */
export const getFormatFileAffiliateUserAsync = async () => {
  const format = await axios
    .get<APIDownloadBulkCreateFormatResponse>('/affiliates/users/download/format')
    .then((response) => {
      return {
        fileName: response.data.result.file_name,
        fileContent: response.data.result.file_content,
      };
    })
    .catch((e) => {
      alert(e.message);
      return null;
    });

  return format;
};

/**
 * @summary 新規連絡先ユーザを一括登録する
 * @param {File} file 一括作成用ファイル
 * @returns {Promise<boolean>} 登録成否
 */
export const bulkCreateAffiliateUserAsync =
  (file: File) =>
  async (dispatch: any): Promise<boolean> => {
    dispatch(commonActions.setIsProcessing(true));
    dispatch(actions.setIsSubmitting(true));

    const formData = new FormData();
    formData.append('affiliates_users_file', file);
    return await axios
      .post('/affiliates/users/bulk_create', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then(() => {
        dispatch(actions.refresh());
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        dispatch(commonActions.setIsProcessing(false));
        dispatch(actions.setIsSubmitting(false));
      });
  };

/**
 * @summary 新規連絡先ユーザを登録する
 * @param {AffiliateUserFormData} params 新規作成フォーム入力データ
 * @returns {void}
 */
export const createAffiliateUserAsync =
  (params: AffiliateUserFormData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post<APIGetAffiliateUserResponse>(`/affiliates/users`, params)
      .then((response) => {
        dispatch(
          actions.setNavigatingTo(
            `/affiliate/user/detail/${response.data.result.affiliate_user_id}`,
          ),
        );
        dispatch(actions.setIsNavigating(true));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 既存の連絡先ユーザを編集する
 * @param {number} userId 連絡先ユーザID
 * @param {AffiliateUserFormData} params 編集フォーム入力データ
 * @returns {void}
 */
export const updateAffiliateUserAsync =
  (userId: number, params: AffiliateUserFormData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .put<APIGetAffiliateUserResponse>(`/affiliates/users/${userId}`, params)
      .then(() => {
        dispatch(actions.setNavigatingTo(`/affiliate/user/detail/${userId}`));
        dispatch(actions.setIsNavigating(true));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 検索パラメータを元に連絡先企業のリストを取得
 * @param {number} page ページ番号
 * @param {number} sort ソート番号
 * @param {string} keyword 検索キーワード
 * @param {SearchParameter} params 詳細検索条件
 * @param {number[]} tagIdList 連絡先検索タグID
 * @returns {void}
 */
export const getAffiliateCompanyListAsync =
  (page: number, sort: number, keyword: string, params: SearchParameter, tagIdList: number[]) =>
  async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetAffiliatesRequestParams = {
      page: page,
      sort: sort,
      size_per_page: LIST_SIZE_PER_PAGE,
    };

    if (keyword) reqParams.keyword = keyword;
    if (params.company_name) reqParams.name = params.company_name;
    if (params.company_address) reqParams.address = params.company_address;
    if (params.industries) reqParams.industries = params.industries;
    if (tagIdList.length) reqParams.tag = tagIdList.join(',');

    await axios
      .get<APIGetAffiliateCompanyListResponse>('/affiliates/companies', {
        params: reqParams,
      })
      .then((response) => {
        const { affiliate_company_list: companyList, display_params: displayParams } =
          response.data.result;

        dispatch(actions.setCompanyList(companyList));
        dispatch(actions.setDisplayParams(displayParams));
      })
      .catch((e) => {
        dispatch(actions.setCompanyList([]));
        dispatch(actions.setDisplayParams(null));
        alert(e.message);
      });

    dispatch(actions.setIsLoading(false));
  };

/**
 * @summary 企業IDを元に連絡先企業を取得
 * @param {number} companyId 企業ID
 * @returns {void}
 */
export const getAffiliateDetailCompanyAsync = (companyId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetAffiliateCompanyResponse>(`/affiliates/companies/${companyId}`)
    .then((response) => {
      const companyDetail = response.data.result;
      dispatch(actions.setCompanyDetail(companyDetail));
    })
    .catch(() => {
      dispatch(actions.setCompanyDetail(null));
    });

  dispatch(actions.setIsLoading(false));
};

/**
 * @summary 新規連絡先企業の一括登録用フォーマットファイルを取得する
 * @returns { {fileName: string, fileContent: string} | null }
 */
export const getFormatFileAffiliateCompanyAsync = async () => {
  const format = await axios
    .get<APIDownloadBulkCreateFormatResponse>('/affiliates/companies/download/format')
    .then((response) => {
      return {
        fileName: response.data.result.file_name,
        fileContent: response.data.result.file_content,
      };
    })
    .catch(() => {
      return null;
    });

  return format;
};

/**
 * @summary 新規連絡先企業を一括登録する
 * @param {File} file 一括作成用ファイル
 * @returns {Promise<boolean>} 登録成否
 */
export const bulkCreateAffiliateCompanyAsync =
  (file: File) =>
  async (dispatch: any): Promise<boolean> => {
    dispatch(commonActions.setIsProcessing(true));
    dispatch(actions.setIsSubmitting(true));

    const formData = new FormData();
    formData.append('affiliates_companies_file', file);
    return await axios
      .post('/affiliates/companies/bulk_create', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then(() => {
        dispatch(actions.refresh());
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        dispatch(commonActions.setIsProcessing(false));
        dispatch(actions.setIsSubmitting(false));
      });
  };

/**
 * @summary 新規連絡先企業を登録する
 * @param {AffiliateCompanyFormData} params 新規作成フォーム入力データ
 * @returns {void}
 */
export const createAffiliateCompanyAsync =
  (params: AffiliateCompanyFormData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post<APIGetAffiliateCompanyResponse>(`/affiliates/companies`, params)
      .then((response) => {
        dispatch(
          actions.setNavigatingTo(`/affiliate/company/detail/${response.data.result.company_id}`),
        );
        dispatch(actions.setIsNavigating(true));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 既存の連絡先企業を編集する
 * @param {number} companyId 連絡先企業ID
 * @param {AffiliateCompanyFormData} params 編集フォーム入力データ
 * @returns {void}
 */
export const updateAffiliateCompanyAsync =
  (companyId: number, params: AffiliateCompanyFormData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .put<APIGetAffiliateCompanyResponse>(`/affiliates/companies/${companyId}`, params)
      .then(() => {
        dispatch(actions.setNavigatingTo(`/affiliate/company/detail/${companyId}`));
        dispatch(actions.setIsNavigating(true));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 連絡先ユーザにタグを設定する
 * @param {{affiliate_user_id: number}[]} userIdList 連絡先ユーザIDリスト
 * @param {number} tagId タグID
 * @returns {void}
 */
export const setUserTagAsync =
  (userIdList: { affiliate_user_id: number }[], tagId: number) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post('/affiliates/users/tags', {
        tag_id: tagId,
        affiliate_user_list: userIdList,
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 連絡先ユーザのタグを解除する
 * @param {{affiliate_user_id: number}[]} userIdList 連絡先ユーザIDリスト
 * @returns {void}
 */
export const deleteUserTagAsync =
  (userIdList: { affiliate_user_id: number }[]) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post('/affiliates/users/tags/delete', {
        affiliate_user_list: userIdList,
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 連絡先企業にタグを設定する
 * @param {{affiliate_company_id: number}[]} companyIdList 連絡先企業IDリスト
 * @param {number} tagId タグID
 * @returns {void}
 */
export const setCompanyTagAsync =
  (companyIdList: { affiliate_company_id: number }[], tagId: number) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post('/affiliates/companies/tags', {
        tag_id: tagId,
        affiliate_company_list: companyIdList,
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 連絡先企業のタグを解除する
 * @param {{affiliate_company_id: number}[]} companyIdList 連絡先企業IDリスト
 * @returns {void}
 */
export const deleteCompanyTagAsync =
  (companyIdList: { affiliate_company_id: number }[]) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post('/affiliates/companies/tags/delete', {
        affiliate_company_list: companyIdList,
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 連絡先ユーザリストをダウンロードする
 * @param {SearchParameter & {id?: string, is_all: boolean, sort?: number, keyword?: string; tag?: string}} params
 * @returns
 */
export const downloadUserListAsync =
  (
    params: SearchParameter & {
      id?: string;
      is_all: boolean;
      sort?: number;
      keyword?: string;
      tag?: string;
    },
  ) =>
  async () => {
    return await axios
      .get('/affiliates/users/download', {
        params: params,
      })
      .then((response) => {
        return response.data.result;
      })
      .catch(() => {
        return null;
      });
  };

/**
 * @summary 連絡先ユーザリストをダウンロードする
 * @param {SearchParameter & {id?: string, is_all: boolean, sort?: number, keyword?: string; tag?: string}} params
 * @returns
 */
export const downloadCompanyListAsync =
  (
    params: SearchParameter & {
      id?: string;
      is_all: boolean;
      sort?: number;
      keyword?: string;
      tag?: string;
    },
  ) =>
  async () => {
    return await axios
      .get('/affiliates/companies/download', {
        params: params,
      })
      .then((response) => {
        return response.data.result;
      })
      .catch(() => {
        return null;
      });
  };

/**
 * @summary 連絡先ユーザを削除する
 * @param {{affiliate_user_id: number}[]} userIdList 連絡先ユーザIDリスト
 * @param {string} navigating ページ遷移先
 * @returns {void}
 */
export const deleteUserListAsync =
  (userIdList: { affiliate_user_id: number }[], navigatingTo = '') =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post('/affiliates/users/delete', {
        affiliate_user_list: userIdList,
      })
      .then(() => {
        dispatch(actions.refresh());
        if (navigatingTo) {
          dispatch(actions.setNavigatingTo(navigatingTo));
          dispatch(actions.setIsNavigating(true));
        }
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 連絡先企業を削除する
 * @param {{affiliate_company_id: number}[]} companyIdList 連絡先企業IDリスト
 * @param {string} navigating ページ遷移先
 * @returns {void}
 */
export const deleteCompanyListAsync =
  (companyIdList: { affiliate_company_id: number }[], navigatingTo = '') =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post('/affiliates/companies/delete', {
        affiliate_company_list: companyIdList,
      })
      .then(() => {
        dispatch(actions.refresh());
        if (navigatingTo) {
          dispatch(actions.setNavigatingTo(navigatingTo));
          dispatch(actions.setIsNavigating(true));
        }
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 郵便番号を元に住所を取得
 * @param {string} zipCode 郵便番号
 * @returns {void}
 */
export const searchAddressAsync = (zipCode: string) => async (dispatch: any) => {
  await axios_
    .get<APIGetAddressResponse>('https://zipcloud.ibsnet.co.jp/api/search', {
      params: { zipcode: zipCode, limit: 1 },
    })
    .then((response) => {
      const results = response.data.results;
      const v = results[0];
      const address: Address = {
        address1: v.address1,
        address2: v.address2,
        address3: v.address3,
        prefCode: v.prefcode,
        zipCode: v.zipcode,
      };
      dispatch(actions.setAddressAutofill(address));
    })
    .catch(() => {
      dispatch(actions.setAddressAutofill(null));
    });
};
