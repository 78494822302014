import axios from '@providers/axiosProvider';
import * as actions from './actions';

import {
  APIAddGroupUsersRequest,
  APICreateGroup,
  APICreateGroupRequest,
  APICreateGroupResponse,
  APIDeleteGroupsRequest,
  APIDeleteGroupUsersRequest,
  APIGetGroupResponse,
  APIGetGroupsRequestParams,
  APIGetGroupsResponse,
  APIGetSelectMembersRequestParams,
  APIGetSelectMembersResponse,
  APIUpdateGroup,
  APIUpdateGroupRequest,
  Group,
  GroupsList,
  GroupsListInfo,
  GroupSubmitData,
  SelectMembersList,
  SelectMembersListInfo,
} from './types';

import { LIST_SIZE_PER_PAGE, LIST_SIZE_PER_PAGE_LARGE } from '@utils/constants';

export const getGroupsAsync = (params: APIGetGroupsRequestParams) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  const reqParams: APIGetGroupsRequestParams = {
    ...params,
    size_per_page: LIST_SIZE_PER_PAGE,
  };

  await axios
    .get<APIGetGroupsResponse>('/groups', { params: reqParams })
    .then((response) => {
      const { group_list: result, display_params: info } = response.data.result;

      const groups: GroupsList[] = result.map(
        (group): GroupsList => ({
          groupId: group.group_id,
          groupName: group.group_name,
          memberNum: group.member_num,
          description: group.description,
        }),
      );

      const groupsListInfo: GroupsListInfo = {
        totalHits: info.total,
        totalPages: info.total_page,
      };

      dispatch(actions.setGroups(groups));
      dispatch(actions.setGroupsListInfo(groupsListInfo));
    })
    .catch(() => {
      dispatch(actions.setGroups([]));
      dispatch(actions.setGroupsListInfo(null));
    });

  dispatch(actions.setIsLoading(false));
};

export const getGroupAsync = (groupId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetGroupResponse>(`/groups/${groupId}`)
    .then((response) => {
      const group = response.data.result;

      const data: Group = {
        groupId: group.group_id,
        groupName: group.group_name,
        memberNum: group.member_num,
        description: group.description,
        userList: group.user_list.map((user) => ({
          userId: user.user_id,
          email: user.email,
          name: user.last_name + ' ' + user.first_name,
          location: user.location,
          belong: user.belong,
          position: user.position,
        })),
      };

      dispatch(actions.setGroup(data));
    })
    .catch(() => {
      dispatch(actions.setGroup(null));
    });

  dispatch(actions.setIsLoading(false));
};

/**
 * @summary グループの登録API実行処理
 * @param {GroupSubmitData} data 登録データ
 * @returns {Promise<void>}
 */
export const createGroupAsync = (data: GroupSubmitData) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  const group: APICreateGroup = {
    group_name: data.groupName,
    description: data.description,
  };

  const requestData: APICreateGroupRequest = group;

  await axios
    .post<APICreateGroupResponse>(`/groups`, requestData)
    .then((response) => {
      dispatch(actions.setIsNavigating(true));
      dispatch(actions.setNavigateTo(`/admin/group/${-1 * response.data.result.group_id}`));
    })
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};

/**
 * @summary グループの更新API実行処理
 * @param {number} groupId 更新対象のグループID
 * @param {GroupSubmitData} data 更新データ
 * @returns {Promise<void>}
 */
export const updateGroupAsync =
  (groupId: number, data: GroupSubmitData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const group: APIUpdateGroup = {
      group_name: data.groupName,
      description: data.description,
    };

    const requestData: APIUpdateGroupRequest = group;

    await axios
      .put(`/groups/${groupId}`, requestData)
      .then(() => {
        dispatch(actions.setIsNavigating(true));
        dispatch(actions.setNavigateTo(`/admin/group/${-1 * groupId}`));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary グループの削除API実行処理
 * @param {number[]} groupIds 削除対象のグループID(複数)
 * @returns {Promise<void>}
 */
export const deleteGroupsAsync = (groupIds: number[]) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  const requestData: APIDeleteGroupsRequest = {
    group_list: groupIds.map((v) => ({
      group_id: v,
    })),
  };

  await axios
    .delete(`/groups`, {
      data: requestData,
    })
    .then(() => {
      dispatch(actions.setIsNavigating(true));
      dispatch(actions.setNavigateTo('/admin/group'));
    })
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};

export const getSelectMembersAsync =
  (groupId: number, params: APIGetSelectMembersRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading2(true));

    const reqParams: APIGetSelectMembersRequestParams = {
      ...params,
      page: 1,
      size_per_page: LIST_SIZE_PER_PAGE_LARGE,
    };

    await axios
      .get<APIGetSelectMembersResponse>(`/groups/${groupId}/group_users/items`, {
        params: reqParams,
      })
      .then((response) => {
        const { user_list: list, display_params: info } = response.data.result;

        const selectMembers: SelectMembersList[] = list.map(
          (v): SelectMembersList => ({
            id: v.user_id,
            name: `${v.last_name} ${v.first_name}`,
          }),
        );

        const selectMembersListInfo: SelectMembersListInfo = {
          totalHits: info.total,
          totalPages: info.total_page,
        };

        dispatch(actions.setSelectMembers(selectMembers));
        dispatch(actions.setSelectMembersListInfo(selectMembersListInfo));
      })
      .catch(() => {
        dispatch(actions.setSelectMembers([]));
        dispatch(actions.setSelectMembersListInfo(null));
      });

    dispatch(actions.setIsLoading2(false));
  };

export const addGroupUsersAsync = (groupId: number, userIds: number[]) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  const requestData: APIAddGroupUsersRequest = {
    user_list: userIds.map((v) => ({ user_id: v })),
  };

  await axios
    .post(`/groups/${groupId}/group_users`, requestData)
    .then(() => {
      dispatch(actions.refresh());
    })
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};

export const deleteGroupUsersAsync =
  (groupId: number, groupUserIds: number[]) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIDeleteGroupUsersRequest = {
      user_list: groupUserIds.map((v) => ({ user_id: v })),
    };

    await axios
      .delete(`/groups/${groupId}/group_users`, { data: requestData })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };
