import { AppState as State } from '@ducks/index';

export const getRefreshId = (state: State) => state.group.refresh;

export const checkIsLoading = (state: State) => state.group.isLoading;
export const checkIsLoading2 = (state: State) => state.group.isLoading2;
export const checkIsSubmitting = (state: State) => state.group.isSubmitting;

/**
 * 画面遷移処理の実行フラグを返却する
 * @param state グループ画面で使用するState
 * @returns {boolean}
 */
export const checkIsNavigating = (state: State) => state.group.isNavigating;

/**
 * 遷移先のURLを返却する
 * @param state グループ画面で使用するState
 * @returns {string | null}
 */
export const getNavigateTo = (state: State) => state.group.navigateTo;

export const getGroups = (state: State) => state.group.groups;
export const getGroupsListInfo = (state: State) => state.group.groupsListInfo;

export const getGroup = (state: State) => state.group.group;

export const getSelectMembers = (state: State) => state.group.selectMembers;
export const getSelectMembersListInfo = (state: State) => state.group.selectMembersListInfo;
