import { GroupState, GroupAction, GroupActionTypes as ActionTypes } from './types';

const initialGroupState: GroupState = {
  refresh: {},
  isLoading: false,
  isLoading2: false,
  isSubmitting: false,
  isNavigating: false,
  navigateTo: null,
  groups: [],
  groupsListInfo: null,
  group: null,
  selectMembers: [],
  selectMembersListInfo: null,
};

const groupReducer = (state: GroupState = initialGroupState, action: GroupAction): GroupState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialGroupState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.LOADING2:
      return { ...state, isLoading2: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.NAVIGATING:
      return { ...state, isNavigating: action.payload };

    case ActionTypes.SET_NAVIGATE_TO:
      return { ...state, navigateTo: action.payload };

    case ActionTypes.SET_GROUPS:
      return { ...state, groups: action.payload };

    case ActionTypes.SET_GROUPS_LIST_INFO:
      return { ...state, groupsListInfo: action.payload };

    case ActionTypes.SET_GROUP:
      return { ...state, group: action.payload };

    case ActionTypes.SET_SELECT_MEMBERS:
      return { ...state, selectMembers: action.payload };

    case ActionTypes.SET_SELECT_MEMBERS_LIST_INFO:
      return { ...state, selectMembersListInfo: action.payload };

    default:
      return state;
  }
};

export default groupReducer;
