import { useMessageSource } from 'react-message-source';
import { TableResizeOptions } from '@uiParts/resizeCheckboxTable/useTableResize';
import { OptionItem } from '@uiParts/selectBox';

/** テーブル一覧表示: 1ページあたりの標準表示件数 */
export const LIST_SIZE_PER_PAGE = 20;

/** テーブル一覧表示: 1ページあたりの標準拡張表示件数 */
export const LIST_SIZE_PER_PAGE_LARGE = 100;

/** 建物の契約単価の限度 */
export const CONTRACT_PRICE_UNIT_LIMIT = 9999999999;

/** int32最大数 */
export const MAX_INT_32 = 2147483647;

/** 数値入力における有効桁数の上限値 */
export const MAX_SIGNIFICANT_FIGURES = 15;

/** 削除操作を示すInputEvent.inputTypeのリスト */
export const DELETE_INPUT_TYPE_LIST = [
  'deleteContentBackward',
  'deleteContentForward',
  'deleteWordBackward',
  'deleteWordForward',
];

/** 都道府県マスタ */
export const MST_PREFECTURES = [
  { id: 1, name: '北海道' },
  { id: 2, name: '青森県' },
  { id: 3, name: '岩手県' },
  { id: 4, name: '宮城県' },
  { id: 5, name: '秋田県' },
  { id: 6, name: '山形県' },
  { id: 7, name: '福島県' },
  { id: 8, name: '茨城県' },
  { id: 9, name: '栃木県' },
  { id: 10, name: '群馬県' },
  { id: 11, name: '埼玉県' },
  { id: 12, name: '千葉県' },
  { id: 13, name: '東京都' },
  { id: 14, name: '神奈川県' },
  { id: 15, name: '新潟県' },
  { id: 16, name: '富山県' },
  { id: 17, name: '石川県' },
  { id: 18, name: '福井県' },
  { id: 19, name: '山梨県' },
  { id: 20, name: '長野県' },
  { id: 21, name: '岐阜県' },
  { id: 22, name: '静岡県' },
  { id: 23, name: '愛知県' },
  { id: 24, name: '三重県' },
  { id: 25, name: '滋賀県' },
  { id: 26, name: '京都府' },
  { id: 27, name: '大阪府' },
  { id: 28, name: '兵庫県' },
  { id: 29, name: '奈良県' },
  { id: 30, name: '和歌山県' },
  { id: 31, name: '鳥取県' },
  { id: 32, name: '島根県' },
  { id: 33, name: '岡山県' },
  { id: 34, name: '広島県' },
  { id: 35, name: '山口県' },
  { id: 36, name: '徳島県' },
  { id: 37, name: '香川県' },
  { id: 38, name: '愛媛県' },
  { id: 39, name: '高知県' },
  { id: 40, name: '福岡県' },
  { id: 41, name: '佐賀県' },
  { id: 42, name: '長崎県' },
  { id: 43, name: '熊本県' },
  { id: 44, name: '大分県' },
  { id: 45, name: '宮崎県' },
  { id: 46, name: '鹿児島県' },
  { id: 47, name: '沖縄県' },
];

/**
 * 機器種別マスタ
 * NOTE: 機器種別は7タイプに変更 -> @summary [設備] 機器種別マスタ を参照
 */

export const MST_EQUIPMENT_TYPE = [
  { id: 1, name: '空調 - 空調機器', category: 1 },
  { id: 2, name: '空調 - ユニット類', category: 1 },
  { id: 3, name: '空調 - ファン類', category: 1 },
  { id: 4, name: '空調 - 自動制御機器', category: 1 },
  { id: 5, name: '空調 - その他', category: 1 },
  { id: 6, name: '電気 - 高圧機器', category: 2 },
  { id: 7, name: '電気 - 発電機', category: 2 },
  { id: 8, name: '電気 - 盤類', category: 2 },
  { id: 9, name: '電気 - 照明器具', category: 2 },
  { id: 10, name: '電気 - 弱電設備', category: 2 },
  { id: 11, name: '電気 - 防災設備', category: 2 },
  { id: 12, name: '電気 - 避雷針', category: 2 },
  { id: 13, name: '電気 - その他', category: 2 },
  { id: 14, name: '衛生防災 - ボイラ類', category: 3 },
  { id: 15, name: '衛生防災 - 水槽類', category: 3 },
  { id: 16, name: '衛生防災 - ポンプ類', category: 3 },
  { id: 17, name: '衛生防災 - 衛生器具', category: 3 },
  { id: 18, name: '衛生防災 - 一般消火機器', category: 3 },
  { id: 19, name: '衛生防災 - 特殊消火機器', category: 3 },
  { id: 20, name: '衛生防災 - 配管材', category: 3 },
  { id: 21, name: '衛生防災 - その他', category: 3 },
  { id: 22, name: '輸送 - 昇降機', category: 4 },
  { id: 23, name: '輸送 - その他', category: 4 },
  { id: 24, name: 'その他 - その他', category: 5 },
];

/**
 * @summary システムで利用する共通の定数を定義する
 * @description 定数に定義したid値はAPI引き渡し用データとして利用する
 * フックもしくは、画面側においては以下のように利用する
 *【フック側コード】例
 * const [fixedTermTaskType, setFixedTermTaskType] = useState('');
 * const fixedTermTaskTypes: OptionItem[] = MST_FIXED_TERM_TASK_TYPES.map((v) => ({
 *    title: v.name,
 *    value: v.id,
 * }));
 *
 * const handleFixedTermTaskTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
 *   setFixedTermTaskType(e.target.value);
 * };
 * return {
 *   // Type 定期業務種別 Value、 Option、Changeイベントハンドラー を画面へ提供
 *   fixedTermTaskType,
 *   fixedTermTaskTypes,
 *   handleFixedTermTaskTypeChange,
 * };
 *【画面側コード】例
 * const {
 *   fixedTermTaskType,
 *   fixedTermTaskTypes,
 *   handleFixedTermTaskTypeChange,
 * } = useFilterOptions();
 *
 * <SelectBox
 *   value={fixedTermTaskType}
 *   options={fixedTermTaskTypes}
 *   onChange={handleFixedTermTaskTypeChange}
 *   headerValue={''}
 *   headerTitle={''}
 * />
 * @returns  定義したシステム共通で利用できる定数
 */
export const useConstants = () => {
  const { getMessage } = useMessageSource();

  /**
   * @summary 定期業務種別(Type)のAPI引き渡し用Idの管理テーブル
   */
  const MST_FIXED_TERM_TASK_TYPES = [
    { id: 1, name: getMessage(`common.fixedTermTaskType.0001`) },
    { id: 2, name: getMessage(`common.fixedTermTaskType.0002`) },
    { id: 3, name: getMessage(`common.fixedTermTaskType.0003`) },
    { id: 4, name: getMessage(`common.fixedTermTaskType.0004`) },
  ];

  /**
   * @summary 定期業務区分(Division)のAPI引き渡し用Idの管理テーブル
   */
  const MST_FIXED_TERM_TASK_DIVISIONS = [
    { id: 1, name: getMessage(`common.fixedTermTaskDivision.0001`) },
    { id: 2, name: getMessage(`common.fixedTermTaskDivision.0002`) },
    { id: 3, name: getMessage(`common.fixedTermTaskDivision.0003`) },
    { id: 4, name: getMessage(`common.fixedTermTaskDivision.0004`) },
    { id: 5, name: getMessage(`common.fixedTermTaskDivision.0005`) },
    { id: 6, name: getMessage(`common.fixedTermTaskDivision.0006`) },
  ];

  /**
   * @summary 対応状況(Status)のAPI引き渡し用Idの管理テーブル
   */
  const MST_FIXED_TERM_TASK_STATUSES = [
    { id: 1, name: getMessage(`common.fixedTermTaskStatus.0001`) },
    { id: 2, name: getMessage(`common.fixedTermTaskStatus.0002`) },
    { id: 3, name: getMessage(`common.fixedTermTaskStatus.0003`) },
    { id: 4, name: getMessage(`common.fixedTermTaskStatus.0004`) },
    { id: 5, name: getMessage(`common.fixedTermTaskStatus.0005`) },
    { id: 6, name: getMessage(`common.fixedTermTaskStatus.0006`) },
  ];

  /**
   * @summary [設備] 機器種別マスタ
   */
  const MST_EQUIPMENT_TYPES = [
    { id: 1, name: '空気調和設備' },
    { id: 2, name: '給排水設備' },
    { id: 3, name: '昇降機設備' },
    { id: 4, name: '弱電設備' },
    { id: 5, name: '強電設備' },
    { id: 6, name: '防災設備' },
    { id: 7, name: 'その他' },
  ];

  /**
   * @summary 案件分類
   * @description
   * 1.不具合・2.問い合わせ・3.その他・4.工事
   */
  const WORK_TYPE_OPTIONS: OptionItem[] = [
    { title: getMessage(`common.workTypeOptions.0001`), value: 1 },
    { title: getMessage(`common.workTypeOptions.0002`), value: 2 },
    { title: getMessage(`common.workTypeOptions.0004`), value: 3 },
    { title: getMessage(`common.workTypeOptions.0003`), value: 4 },
  ];

  /**
   * @summary 優先度
   * @description
   * 1.高・2中・3.低
   */
  const WORK_PRIORITY_OPTIONS: OptionItem[] = [
    { title: getMessage(`common.workPriorityOption.0001`), value: 1 },
    { title: getMessage(`common.workPriorityOption.0002`), value: 2 },
    { title: getMessage(`common.workPriorityOption.0003`), value: 3 },
  ];

  /**
   * @summary 対応状況
   * @description
   * 1.未実施・2対応中・3.査収待ち・4.査収完了・5.保留
   */
  const WORK_STATUS_OPTIONS: OptionItem[] = [
    { title: getMessage(`common.workStatusOptions0001`), value: 1 },
    { title: getMessage(`common.workStatusOptions0002`), value: 2 },
    { title: getMessage(`common.workStatusOptions0003`), value: 3 },
    { title: getMessage(`common.workStatusOptions0004`), value: 4 },
    { title: getMessage(`common.workStatusOptions0005`), value: 5 },
  ];

  /**
   * @summary 対応状況（査収待ち前段階）
   * @description
   * 1.未実施・2対応中・5.保留
   */

  const WORK_CHANGEABLE_STATUS_OPTIONS: OptionItem[] = [
    { title: getMessage(`common.workStatusOptions0001`), value: 1 },
    { title: getMessage(`common.workStatusOptions0002`), value: 2 },
    { title: getMessage(`common.workStatusOptions0005`), value: 5 },
  ];

  return {
    MST_FIXED_TERM_TASK_TYPES,
    MST_FIXED_TERM_TASK_DIVISIONS,
    MST_FIXED_TERM_TASK_STATUSES,
    MST_EQUIPMENT_TYPES,
    WORK_TYPE_OPTIONS,
    WORK_PRIORITY_OPTIONS,
    WORK_STATUS_OPTIONS,
    WORK_CHANGEABLE_STATUS_OPTIONS,
  };
};

/** リサイズテーブルを使用する一覧画面 */
export type USE_RESIZE_TABLE_PAGE =
  /** 建物一覧 */
  | 'property'
  /** 契約一覧 */
  | 'contract'
  /** 設備一覧 */
  | 'equipment'
  /** 年次計画 - 予定表 */
  | 'annualSchedule'
  /** 年次計画 - 計画集計 */
  | 'annualTable'
  /** 月次計画 - 予定表 */
  | 'monthlySchedule'
  /** 月次計画 - 計画管理 */
  | 'monthlyTable'
  /** 日次計画 - 予定表 */
  | 'dailySchedule'
  /** 査収一覧 */
  | 'checking'
  /** 定期業務一覧 */
  | 'inspection'
  /** 工事一覧 */
  | 'construction'
  /** 案件一覧 */
  | 'work'
  /** ドキュメント */
  | 'archive'
  /** 点検表一覧 */
  | 'inspectionChecklist'
  /** 連絡先一覧 */
  | 'affiliateUser'
  /** 企業一覧 */
  | 'affiliateCompany'
  /** ユーザー管理 */
  | 'user'
  /** グループ管理 */
  | 'group';

/**
 * @summary リサイズテーブルの設定
 * @description
 * hasCheckbox: true とした場合は、返却配列columnWidthsの先頭にカラムが追加される。
 * fixedColumnWidths は、チェックボックス分をのぞいた幅を指定する
 * fixedColumnMinWidths は、チェックボックス分をのぞいた幅を指定する
 */
export const RESIZE_TABLE_OPTIONS: { [key in USE_RESIZE_TABLE_PAGE]: TableResizeOptions } = {
  /** 建物一覧 */
  property: {
    hasCheckbox: true,
    fixedColumnWidths: [
      300, // 建物名
      80, // 契約数
      80, // 異常のある設備数
      80, // 未完了の工事数
      80, // 未完了の案件数
      60, // URL
      300, // 住所
      200, // 所有者
      120, // 電話番号
      200, // 階数
      200, // 駐車場
      100, // 更新日
      200, // 更新者
    ],
    fixedColumnMinWidths: [
      80, // 建物名
      78, // 契約数
      78, // 異常のある設備数
      78, // 未完了の工事数
      78, // 未完了の案件数
      55, // URL
      70, // 住所
      70, // 所有者
      80, // 電話番号
      60, // 階数
      70, // 駐車場
      70, // 更新日
      70, // 更新者
    ],
  },
  /** 契約一覧 */
  contract: {
    hasCheckbox: true,
    fixedColumnWidths: [
      300, // 建物名
      200, // 計画名
      100, // 契約種別
      95, // 定期業務種別
      90, // 定期業務区分
      90, // 周期
      120, // 開始日
      120, // 終了日
      160, // 協力会社名
      120, // 請求単価
      120, // 支払単価
      100, // 契約状況
      80, // 点検表
      160, // 報告書フォーマット
    ],
    fixedColumnMinWidths: [
      70, // 建物名
      80, // 計画名
      95, // 契約種別
      92, // 定期業務種別
      80, // 定期業務区分
      60, // 周期
      80, // 開始日
      70, // 終了日
      100, // 協力会社名
      85, // 請求単価
      85, // 支払単価
      85, // 契約状況
      70, // 点検表
      150, // 報告書フォーマット
    ],
  },
  /** 設備一覧 */
  equipment: {
    hasCheckbox: true,
    fixedColumnWidths: [
      160, // 設備名
      160, // 機器種別
      100, // 型番
      200, // 建物名
      100, // フロア
      140, // 部屋
      120, // 設置年月
      120, // 経過年数
      100, // 点検中
      110, // 案件対応中
      100, // 異常
    ],
    fixedColumnMinWidths: [
      80, // 設備名
      95, // 機器種別
      70, // 型番
      80, // 建物名
      80, // フロア
      70, // 部屋
      85, // 設置年月
      85, // 経過年数
      70, // 点検中
      100, // 案件対応中
      60, // 異常
    ],
  },
  /** 年次計画 - 予定表 */
  annualSchedule: {
    hasCheckbox: false,
    fixedColumnWidths: [
      200, // 建物名
      100, // 契約種別
      130, // 定期業務区分
      130, // 計画名
      130, // 定期業務種別
      100, // 周期
      160, // カスタム設定
    ],
    fixedColumnMinWidths: [
      80, // 建物名
      95, // 契約種別
      120, // 定期業務区分
      80, // 計画名
      120, // 定期業務種別
      60, // 周期
      115, // カスタム設定
    ],
  },
  /** 年次計画 - 計画集計 */
  annualTable: {
    hasCheckbox: false,
    fixedColumnWidths: [
      200, // 建物名
      140, // 計画完了月
      120, // 契約種別
      130, // 定期業務区分
      130, // 定期業務種別
      150, // 計画名
      140, // 請求額
      140, // 支払額
      140, // 粗利額
      140, // 粗利率
    ],
    fixedColumnMinWidths: [
      80, // 建物名
      110, // 計画完了月
      95, // 契約種別
      120, // 定期業務区分
      120, // 定期業務種別
      80, // 計画名
      140, // 請求額
      140, // 支払額
      140, // 粗利額
      140, // 粗利率
    ],
  },
  /** 月次計画 - 予定表 */
  monthlySchedule: {
    hasCheckbox: false,
    fixedColumnWidths: [
      160, // 建物名
      160, // 計画名
    ],
    fixedColumnMinWidths: [
      80, // 建物名
      80, // 計画名
    ],
  },
  /** 月次計画 - 計画管理 */
  monthlyTable: {
    hasCheckbox: true,
    fixedColumnWidths: [
      200, // 建物名
      140, // 計画名
      120, // 契約種別
      130, // 定期業務区分
      130, // 定期業務種別
      100, // 対応状況
      120, // 開始日
      100, // 開始時間
      120, // 終了日
      110, // 終了時間
      140, // 監督者
      140, // 作業者
      140, // 協力会社名
      140, // 予定調整先
      140, // 作業メモ
      100, // 自動メール送信状況 - 予定調整
      100, // 自動メール送信状況 - 確定連絡
      100, // 自動メール送信状況 - 事前連絡
      100, // 自動メール送信状況 - 報告依頼
      120, // 契約詳細で設定された予定日時 - 開始日
      100, // 契約詳細で設定された予定日時 - 開始時間
      120, // 契約詳細で設定された予定日時 - 終了日
      100, // 契約詳細で設定された予定日時 - 終了時間
      140, // 販売結果 - 請求額
      140, // 販売結果 - 支払額
      140, // 販売結果 - 粗利額
      100, // 販売結果 - 粗利率
    ],
    fixedColumnMinWidths: [
      80, // 建物名
      80, // 計画名
      95, // 契約種別
      120, // 定期業務区分
      120, // 定期業務種別
      85, // 対応状況
      70, // 開始日
      85, // 開始時間
      70, // 終了日
      85, // 終了時間
      70, // 監督者
      70, // 作業者
      100, // 協力会社名
      100, // 予定調整先
      85, // 作業メモ
      85, // 自動メール送信状況 - 予定調整
      85, // 自動メール送信状況 - 確定連絡
      85, // 自動メール送信状況 - 事前連絡
      85, // 自動メール送信状況 - 報告依頼
      70, // 契約詳細で設定された予定日時 - 開始日
      85, // 契約詳細で設定された予定日時 - 開始時間
      70, // 契約詳細で設定された予定日時 - 終了日
      85, // 契約詳細で設定された予定日時 - 終了時間
      70, // 販売結果 - 請求額
      70, // 販売結果 - 支払額
      70, // 販売結果 - 粗利額
      70, // 販売結果 - 粗利率
    ],
  },
  /** 日次計画 - 予定表 */
  dailySchedule: {
    hasCheckbox: false,
    fixedColumnWidths: [
      180, // 建物名
      180, // 計画名
    ],
    fixedColumnMinWidths: [
      80, // 建物名
      80, // 計画名
    ],
  },
  /** 査収一覧 */
  checking: {
    hasCheckbox: true,
    fixedColumnWidths: [
      50, // ▽
      130, // 計画種別
      100, // 開始日
      120, // 最終更新日
      100, // 作業分類
      200, // 計画名
      90, // 異常件数
      200, // コメント
      120, // 最新実施者
      200, // 建物名
    ],
    fixedColumnMinWidths: [
      40, // ▽
      95, // 計画種別
      80, // 開始日
      110, // 最終更新日
      95, // 作業分類
      70, // 計画名
      85, // 異常件数
      85, // コメント
      110, // 最新実施者
      80, // 建物名
    ],
  },
  /** 定期業務一覧 */
  inspection: {
    hasCheckbox: false,
    fixedColumnWidths: [
      110, // 対応状況
      140, // 計画開始日時
      140, // 最終更新日
      120, // 点検分類
      250, // 計画名
      250, // コメント
      120, // 監督者
      120, // 作業者
      120, // 実施者
      120, // 承認者
      120, // 建物名
    ],
    fixedColumnMinWidths: [
      95, // 対応状況
      120, // 計画開始日時
      110, // 最終更新日
      95, // 点検分類
      80, // 計画名
      85, // コメント
      70, // 監督者
      70, // 作業者
      70, // 実施者
      70, // 承認者
      70, // 建物名
    ],
  },
  /** 工事一覧 */
  construction: {
    hasCheckbox: false,
    fixedColumnWidths: [
      150, // 対応状況
      110, // 開始日
      470, // 計画名
      130, // 最新実施者
      400, // コメント
      140, // 最終更新日時
      170, // 建物名
    ],
    fixedColumnMinWidths: [
      95, // 対応状況
      80, // 開始日
      70, // 計画名
      110, // 最新実施者
      85, // コメント
      120, // 最終更新日時
      80, // 建物名
    ],
  },
  /** 案件一覧 */
  work: {
    hasCheckbox: false,
    fixedColumnWidths: [
      100, // 対応状況
      90, // 優先度
      110, // 開始日
      140, // 最終更新日時
      120, // 期限
      130, // 作業分類
      250, // 計画名
      280, // コメント
      130, // 最新実施者
      130, // 建物名
    ],
    fixedColumnMinWidths: [
      95, // 対応状況
      80, // 優先度
      80, // 開始日
      120, // 最終更新日時
      60, // 期限
      95, // 作業分類
      80, // 計画名
      85, // コメント
      100, // 最新実施者
      70, // 建物名
    ],
  },
  /** ドキュメント */
  archive: {
    hasCheckbox: false,
    fixedColumnWidths: [
      570, // 名前
      140, // 更新日時
      125, // 更新者
      150, // ファイルサイズ
      48, // アイコンエリア
    ],
    fixedColumnMinWidths: [
      70, // 名前
      95, // 更新日時
      80, // 更新者
      135, // ファイルサイズ
      48, // アイコンエリア
    ],
  },
  /** 点検表一覧 */
  inspectionChecklist: {
    hasCheckbox: true,
    fixedColumnWidths: [
      200, // 建物名
      200, // 点検表名
      300, // 点検表の説明
      170, // 参照中の定期業務数
      170, // 報告書フォーマット
      120, // ステータス
    ],
    fixedColumnMinWidths: [
      80, // 建物名
      95, // 点検表名
      115, // 点検表の説明
      165, // 参照中の定期業務数
      165, // 報告書フォーマット
      110, // ステータス
    ],
  },
  /** 連絡先一覧 */
  affiliateUser: {
    hasCheckbox: true,
    fixedColumnWidths: [
      200, // 会社名
      100, // 氏名
      100, // 部署名
      100, // 役職
      160, // TEL1
      160, // TEL2
      160, // 携帯電話
      160, // FAX
      200, // メールアドレス
      200, // URL
      200, // 住所
      200, // 登録日
      100, // 登録者
      200, // 更新日
      100, // 更新者
    ],
    fixedColumnMinWidths: [
      80, // 会社名
      70, // 氏名
      70, // 部署名
      60, // 役職
      65, // TEL1
      65, // TEL2
      85, // 携帯電話
      55, // FAX
      130, // メールアドレス
      55, // URL
      60, // 住所
      80, // 登録日
      80, // 登録者
      70, // 更新日
      70, // 更新者
    ],
  },
  /** 企業一覧 */
  affiliateCompany: {
    hasCheckbox: true,
    fixedColumnWidths: [
      200, // 会社名
      100, // 連絡先数
      100, // 過去の作業件数
      100, // 契約件数
      160, // URL
      160, // 住所
      160, // 電話番号
      110, // 代表者氏名
      100, // 業種
      100, // 更新者
      100, // 更新日
    ],
    fixedColumnMinWidths: [
      80, // 会社名
      95, // 連絡先数
      85, // 過去の作業件数
      85, // 契約件数
      55, // URL
      60, // 住所
      85, // 電話番号
      100, // 代表者氏名
      60, // 業種
      70, // 更新者
      70, // 更新日
    ],
  },
  /** ユーザー管理 */
  user: {
    hasCheckbox: false,
    fixedColumnWidths: [
      160, // ユーザー名
      190, // メールアドレス
      120, // 拠点
      120, // 所属
      120, // 役職
      90, // 管理者
      100, // 状態
    ],
    fixedColumnMinWidths: [
      110, // ユーザー名
      130, // メールアドレス
      70, // 拠点
      70, // 所属
      70, // 役職
      80, // 管理者
      70, // 状態
    ],
  },
  /** グループ管理 */
  group: {
    hasCheckbox: false,
    fixedColumnWidths: [
      250, // グループ名
      530, // 説明
      120, // メンバー数
    ],
    fixedColumnMinWidths: [
      110, // グループ名
      60, // 説明
      110, // メンバー数
    ],
  },
};
