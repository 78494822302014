import type { Action, PayloadAction } from '@reduxjs/toolkit';

export type GroupState = {
  refresh: Record<string, never>;
  isLoading: boolean;
  isLoading2: boolean;
  isSubmitting: boolean;
  /** 画面遷移処理の実行フラグ */
  isNavigating: boolean;
  /** 遷移先のURL */
  navigateTo: string | null;
  groups: GroupsList[];
  groupsListInfo: GroupsListInfo | null;
  group: Group | null;
  selectMembers: SelectMembersList[];
  selectMembersListInfo: SelectMembersListInfo | null;
};

export enum GroupActionTypes {
  INIT = 'group/init',
  REFRESH = 'group/refresh',
  LOADING = 'group/set/isLoading',
  LOADING2 = 'group/set/isLoading2',
  SUBMITTING = 'group/set/isSubmitting',
  NAVIGATING = 'group/set/isNavigating',
  SET_NAVIGATE_TO = 'group/set/navigateTo',
  SET_GROUPS = 'group/set/groups',
  SET_GROUPS_LIST_INFO = 'group/set/groupsListInfo',
  SET_GROUP = 'group/set/group',
  SET_SELECT_MEMBERS = 'group/set/selectMembers',
  SET_SELECT_MEMBERS_LIST_INFO = 'group/set/selectMembersListInfo',
}

export type GroupAction =
  | Action<GroupActionTypes.INIT>
  | Action<GroupActionTypes.REFRESH>
  | PayloadAction<boolean, GroupActionTypes.LOADING>
  | PayloadAction<boolean, GroupActionTypes.LOADING2>
  | PayloadAction<boolean, GroupActionTypes.SUBMITTING>
  /** 画面遷移処理の実行フラグを保持 */
  | PayloadAction<boolean, GroupActionTypes.NAVIGATING>
  /** 遷移先のURLを保持 */
  | PayloadAction<string | null, GroupActionTypes.SET_NAVIGATE_TO>
  | PayloadAction<GroupsList[], GroupActionTypes.SET_GROUPS>
  | PayloadAction<GroupsListInfo | null, GroupActionTypes.SET_GROUPS_LIST_INFO>
  | PayloadAction<Group | null, GroupActionTypes.SET_GROUP>
  | PayloadAction<SelectMembersList[], GroupActionTypes.SET_SELECT_MEMBERS>
  | PayloadAction<SelectMembersListInfo | null, GroupActionTypes.SET_SELECT_MEMBERS_LIST_INFO>;

// -- State Types ---------------------------------------------------------- //

export type GroupsList = {
  groupId: number;
  groupName: string;
  memberNum: number;
  description: string;
};

export type Group = GroupsList & {
  userList: GroupUsersList[];
};

export type GroupUsersList = {
  userId: number;
  email: string;
  name: string;
  location: string;
  belong: string;
  position: string;
  status?: number;
};

export type GroupsListInfo = {
  totalHits: number;
  totalPages: number;
};

export type GroupSubmitData = {
  groupName: string;
  description: string;
};

export type SelectMembersList = {
  id: number;
  name: string;
};

export type SelectMembersListInfo = {
  totalHits: number;
  totalPages: number;
};

// -- API Types ------------------------------------------------------------ //

export type APIDisplayParam = {
  readonly total: number;
  readonly total_page: number;
};

export type APIGroups = {
  readonly group_id: number;
  readonly group_name: string;
  readonly member_num: number;
  readonly description: string;
};

export type APIGroup = APIGroups & {
  readonly user_list: {
    readonly user_id: number;
    readonly email: string;
    readonly last_name: string;
    readonly first_name: string;
    readonly belong: string;
    readonly location: string;
    readonly position: string;
  }[];
};

export type APICreateGroup = {
  readonly group_name: string;
  readonly description: string;
};

export type APIUpdateGroup = APICreateGroup;

export type APISelectMembers = {
  readonly user_id: number;
  readonly last_name: string;
  readonly first_name: number;
};

export type APIGetGroupsRequestParams = {
  keyword?: string;
  page?: number;
  size_per_page?: number;
  sort?: number;
};
export type APIGetGroupsResponse = {
  result: {
    group_list: APIGroups[];
    display_params: APIDisplayParam;
  };
};

export type APIGetGroupResponse = {
  result: APIGroup;
};

export type APICreateGroupRequest = APICreateGroup;
export type APICreateGroupResponse = {
  result: { group_id: number };
};

export type APIUpdateGroupRequest = APIUpdateGroup;

export type APIDeleteGroupsRequest = {
  group_list: { group_id: number }[];
};

export type APIGetSelectMembersRequestParams = {
  keyword: string;
  page?: number;
  size_per_page?: number;
};
export type APIGetSelectMembersResponse = {
  result: {
    user_list: APISelectMembers[];
    display_params: APIDisplayParam;
  };
};

export type APIAddGroupUsersRequest = {
  user_list: { user_id: number }[];
};

export type APIDeleteGroupUsersRequest = {
  user_list: { user_id: number }[];
};
